/* Editor Container */
.editor-container {
  flex: 1;                /* Grow to fill remaining width */
  display: flex;
  flex-direction: column; /* Existing or optional; up to you */
  margin-top: 0;          /* Remove if previously set */
  height: 100%;   
  flex-direction: row; /* Horizontal layout */
  background-color: black; /* Video margins color */
  position: relative;
}

/* Import the Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Indicator Scroll Container */
.indicator-scroll-container {
  position: relative;
  right: 0;
  top: 0;
  width: 15vw;
  height: 100%;
  background: linear-gradient(to bottom right, #f7f7f7, #e0e0e0);
  overflow: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: -8px 0 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 10px 0 0 10px;
  z-index: 5;
  font-family: 'Roboto', sans-serif;
}

/* Hide the annotation list when form is active */
.annotation-list.hidden {
  display: none;
}

/* Annotation List */
.annotation-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  padding-right: 10px;
  position: relative;
}

/* No Annotations Message */
.annotation-list:empty::before {
  content: "There are no annotations yet.";
  display: block;
  text-align: center;
  color: #6c757d;
  font-size: 1rem;
  margin-top: 20px;
}

/* Annotation Form Overlay */
.annotation-form-overlay {
  position: fixed; /* Ensures the overlay covers the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9); /* Light, semi-transparent background */
  z-index: 100; /* Ensure it's above all other elements */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.annotation-form-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10; /* Higher than the annotation list */
  display: flex;
  justify-content: center;
  align-items: center;
  /* Optional: add a semi-transparent background if you want to dim the list behind */
  background: linear-gradient(to bottom right, #f7f7f7, #e0e0e0);
}


.annotation-time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.annotation-form-header {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;  /* Adjust as desired */
  margin-bottom: 4px;
}

.paused-time {
  font-size: 0.8rem;
  color: #555;
}

/* Full-Page Annotation Save Modal */
.annotation-save-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  z-index: 9999; /* High z-index to ensure it overlays everything */
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all; /* Blocks interactions on the underlying page */
}

/* Modal Content */
.annotation-save-modal-content {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0,0,0,0.3);
}

/* Loader Spinner - Annotation Save Loader */
.annotation-save-loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem auto; /* Center spinner and add spacing below */
}

/* Spinner Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Annotation Form */
.annotation-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  width: 100%;
  max-width: 800px; /* Increased to utilize more space */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


/* Paused Time Styling */
.paused-time {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #343a40;
}

/* Form Group Styling */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

/* Label Styling */
.form-group label {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 8px;
  color: #343a40;
  font-size: 1rem;
}

/* Icons in Labels */
.form-group label i {
  margin-right: 8px;
  color: #007bff;
}

/* Input and Textarea Styling */
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 16px 20px;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

/* Placeholder Styling */
.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #6c757d;
  font-style: italic;
}

/* Textarea Specific */
.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

/* Annotation Delete Button Styling */
.annotation-delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  color: #6c757d;
  font-size: 1.1rem;
  cursor: pointer;
  padding: 4px;
  z-index: 10;
  transition: color 0.3s;
  line-height: 1;
}

.annotation-delete-button::after {
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
}

.annotation-delete-button:hover {
  color: #e74c3c; /* Change color to red on hover */
}

/* Annotation Card Styling */
.annotation-card {
  position: relative;
  background-color: #fdfdfd;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s, border-color 0.3s;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  z-index: 1;
  overflow-y: auto;
}


/* Hover Effect for Annotation Card */
.annotation-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
}

/* Time Styling */
.annotation-card .annotation-time {
  font-size: 0.6rem;
  color: #888;
  margin-bottom: 10px;
  word-wrap: break-word;
  word-break: break-word;
}

/* Title Styling */
.annotation-card .annotation-title {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #222;
  word-wrap: break-word;
  word-break: break-word;
}

/* Description Styling */
.annotation-card .annotation-commentary {
  font-size: 0.75rem;
  color: #555;
  line-height: 1.5;
  word-wrap: break-word;
  word-break: break-word;
}

/* Ensure content doesn't exceed the card's width */
.annotation-card .annotation-time,
.annotation-card .annotation-title,
.annotation-card .annotation-commentary {
  max-width: 100%;
  overflow: hidden;
}

/* Improved Card Focus for Accessibility */
.annotation-card:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  border-color: rgba(0, 123, 255, 0.7);
}



/* Responsive Design */
@media (max-width: 768px) {
  .annotation-form {
    max-width: 90%;
    padding: 20px;
  }

  .indicator-scroll-container {
    width: 100%;
    height: auto;
    position: relative;
    box-shadow: none;
    border-radius: 0;
  }

  .annotation-form-overlay {
    padding: 10px;
  }

  .annotation-form {
    padding: 20px;
    max-width: 90%;
  }
}



/* Add to your CSS file */
.indicator-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-5px);
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
  z-index: 10;
}












.editor-container.full-screen {
  width: 100%;
  max-height: 100vh;
}

.editor-container:hover .video-controls-container,
.editor-container:focus-within .video-controls-container,
.editor-container.paused .video-controls-container {
  opacity: 1;
}


/* Video Wrapper */
.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  min-height: calc(100vh - 60px);
}

/* Video Element */
.video-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  left: 0;
  min-height: calc(100vh - 60px);

}

/* Canvases */
.video-wrapper canvas#coldCanvas,
.video-wrapper canvas#hotCanvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
  /* VISUALLY DEBUG CANVAS*/
  /*border: 2px solid red;  */
}



/* Fullscreen Mode - When video-wrapper is fullscreen */
.video-wrapper:fullscreen {
  width: 100vw;
  height: 100vh;
}

/* Fullscreen Mode - Video inside fullscreen */
.video-wrapper:fullscreen video {
  object-fit: contain; /* Fill the screen without maintaining aspect ratio */
}

/* Fullscreen Mode - Canvases in fullscreen */
.video-wrapper:fullscreen canvas#coldCanvas,
.video-wrapper:fullscreen canvas#hotCanvas {
  width: 100%;
  height: 100%;
}

/* Video Controls Container (existing) */
.video-controls-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: lightgrey;
  z-index: 100;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.video-controls-container::before {
  content: "";
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
  width: 100%;
  aspect-ratio: 6 / 1;
  z-index: -1;
  pointer-events: none;
}

/* New Annotation Details Bar */
.annotation-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Remove any extra gap between the two text elements */
  gap: 0;
}

.annotation-details-title,
.annotation-details-description {
  background: rgba(0, 0, 0, 0.75);
  padding: 0.2rem 0.4rem;  /* minimal internal padding for breathing room */
  border-radius: 1rem;
  display: block;         /* use block to avoid inline-block baseline spacing issues */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40vw;        /* cap the width at 40% of the viewport width */
  margin: 0;              /* remove any default margins */
  line-height: 1.2;       /* adjust line-height as needed */
}

.annotation-details-title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

.annotation-details-description {
  color: #ddd;
  font-size: 1rem;
}

.timeline-container {
  margin-top: 1rem; /* for some vertical spacing */
}

/* Video Controls */
.video-controls-container .controls {
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem;
  align-items: center;
}

.video-controls-container .controls button {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  height: 30px;
  width: 30px;
  font-size: 1.1rem;
  cursor: pointer;
  opacity: 0.85;
  transition: opacity 150ms ease-in-out;
}

.video-controls-container .controls button:hover {
  opacity: 1;
}

.video-controls-container .controls button.wide-btn {
  width: 50px;
}

/* Duration Container */
.duration-container {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  flex-grow: 1;
}

/* Timeline */
.timeline-container {
  height: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.timeline {
  background-color: rgba(100, 100, 100, .5);
  height: 3px;
  width: 100%;
  position: relative;
}

/* Progress bar */
.timeline::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: calc(100% - var(--progress-position) * 100%);
  background-color: red;
}

/* Preview bar */
.timeline::before {
  content: "";
  position: absolute;
  left: calc(var(--progress-position) * 100%);
  top: 0;
  bottom: 0;
  right: calc(100% - var(--preview-position) * 100%);
  background-color: rgba(150, 150, 150, 0.5);
  display: none;
}

/* Show preview on hover/scrubbing */
.timeline-container:hover .timeline::before,
.video-container.scrubbing .timeline::before {
  display: block;
}

/* Separate thumb indicator using a new element */
.timeline-container { 
  position: relative;
}

.timeline-container::after {
  content: "";
  position: absolute;
  height: 9px;
  width: 9px;
  background-color: red;
  border-radius: 50%;
  top: 50%;
  left: calc(var(--progress-position) * 100%);
  transform: translate(-50%, -50%) scale(0);
  transition: transform 150ms ease-in-out;
  pointer-events: none;
}

/* Show thumb on hover OR when scrubbing */
.timeline-container:hover::after,
.editor-container.scrubbing .timeline-container::after {
  transform: translate(-50%, -50%) scale(1);
}

/* Thumb indicator that shows current position */
.thumb-indicator {
  --scale: 0;
  position: absolute;
  transform: translateX(-50%) scale(var(--scale));
  height: 200%;
  top: -50%;
  left: calc(var(--progress-position) * 100%);
  background-color: red;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
}

.timeline-container:hover .thumb-indicator {
  --scale: 1;
}

.timeline-container:hover .timeline::before {
  background-color: rgb(150, 150, 150);
}

/* When scrubbing (clicking and dragging) */
.timeline-container.scrubbing .thumb-indicator {
  --scale: 1;
}

.timeline-container.scrubbing .timeline::before {
  background-color: rgb(150, 150, 150);
}

.video-container.scrubbing .timeline-container {
  --scale: 1;
}

/* Style for the clickable timeline indicator */
.timeline-indicator {
  position: absolute;
  width: 5px;  /* Slim but visible width */
  height: 20px;  /* Tall enough */
  background-color: rgba(255, 99, 71, 0.7); /* Pale red */
  border: 1.5px solid rgba(255, 99, 71, 0.9);  /* Slightly darker pale red for differentiation */
  border-radius: 4px;  /* Slight rounding */
  top: -8px;  /* Adjust to center on the timeline */
  left: 0;
  transform: translateX(-50%);
  cursor: pointer;
  transition: transform 0.2s ease-in-out, 
              box-shadow 0.3s ease-in-out, 
              background-color 0.2s ease-in-out, 
              border-color 0.2s ease-in-out, 
              z-index 0.1s ease-in-out;
  z-index: 10;  /* Increased z-index to ensure it's above other elements */
  padding: 0;  /* No padding */
  outline: none;  /* No focus outline */
  box-sizing: border-box;  /* Ensure the border doesn't increase the size */
}

/* Pseudo-element to increase the clickable area */
.timeline-indicator::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;  /* Increased clickable width */
  height: 40px; /* Increased clickable height */
  transform: translate(-50%, -50%);
  background: transparent; /* Invisible background */
  pointer-events: auto; /* Ensure it can capture pointer events */
}

/* Hover effect with pale red border for contrast */
.timeline-indicator.hovered {
  background-color: rgba(255, 99, 71, 0.9);  /* Slightly more opaque pale red */
  border-color: rgba(255, 99, 71, 1);  /* Darker pale red for contrast */
  border-width: 0.1px;
  box-shadow: 0 0 10px rgba(255, 99, 71, 0.5);  /* Softer glowing effect */
  transform: translate(-50%, -5px) scaleY(1.2);  /* Move up 5px and only scale vertically */
  z-index: 10;  /* Bring the hovered element to the front */
}

/* When actively clicked */
.timeline-indicator:active {
  background-color: rgba(255, 99, 71, 0.9);  /* Light pale red background on click */
  border-color: rgba(255, 99, 71, 1);  /* Darker pale red border on click */
  transform: translate(-50%, -5px) scaleY(1.15);  /* Vertical scale and slight translation upwards */
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.8);  /* Brighter yellow glow on click */
  z-index: 10;  /* Bring the active element to the front */
}

/* Glowing effect for special states */
.timeline-indicator.glowing {
  box-shadow: 0 0 15px rgba(255, 215, 0, 1); /* Brighter glowing yellow effect */
  z-index: 20; /* Bring the glowing indicator to the front */
  border-color: rgba(255, 215, 0, 1); /* Brighter golden border */
}



/* VideoPlayer.css */

.placeholder-btn {
  height: 100%;
  width: 100%;
  cursor: default;
  pointer-events: none;
}

.placeholder-btn-min {
  width: 0px !important;
  height: 0px !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  overflow: hidden !important;
  cursor: default !important;
  pointer-events: none !important;
}

.placeholder-btn-min svg {
  display: none;
}



.edit-btn.presentation-edit svg path {
  fill: white;
}

/* Hover and Active Styles */
.draw-btn:hover, .arrow-btn:hover, .circle-btn:hover,
.edit-btn.gen-btn:hover {
  transform: scale(1.1);
}

.edit-btn.gen-btn:active {
  transform: scale(1);
}

.edit-btn.gen-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}


/* Base styles for the save button */
.save-btn.gen-btn {
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.2s ease-in-out;
  outline: none;
}

/* Hover and Active Styles */
.trash-btn.gen-btn:hover,
.save-btn.gen-btn:hover {
  transform: scale(1.1);
}
.trash-btn.gen-btn:active ,
.save-btn.gen-btn:active {
  transform: scale(1);
}






/* Save Button 2 Styling */
.save-btn-2 {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0; /* Remove padding for precise sizing */
  width: 35px; /* Set size */
  height: 35px; /* Set size */
  display: flex; /* Flex display for centering */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  margin: auto; /* Center the button within the parent container */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  outline: none;
}

/* Styling for the SVG inside Save Button 2 */
.save-btn-2 svg {
  width: 100%; /* Make SVG fill the button */
  height: 100%; /* Make SVG fill the button */
  fill: #000000; /* Default color */
}

/* Hover Effect */
.save-btn-2:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Active State */
.save-btn-2:active {
  transform: scale(1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Disabled State */
.save-btn-2:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  box-shadow: none;
}





/* Edit Tools Sidebar */
.edit-tools-container {
  margin-top: -60px;
  width: 60px; /* Adjust width as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  flex-shrink: 0; /* Prevent shrinking */
  position: relative; /* No change needed */
  min-height: 100%;
}


/* Edit Button */
.edit-tools-container .gen-btn {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  height: 35px;
  width: 35px;
  font-size: 1.1rem;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.80;
  transition: opacity 100ms ease-in-out;
  margin-top: 15px;
}


.edit-tools-container .gen-btn:hover {
  opacity: 1;
}

.edit-tools-container .gen-btn.active {
  opacity: 1;
  /* Additional styling if needed */
}

/* Hide the edit tools by default */
.edits {
  display: none;
  transition: opacity 0.3s ease;
  opacity: 0;
  width:45px;
  background-color: #e0e0e0;
  border-radius: 8px; /* Rounded corners for a cohesive group look */
  padding: 10px; /* Padding to add space around the buttons */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
  margin-bottom:20px;
}

.edits.presentation-edits {
  background-color: #333;  /* Dark background for presentation */
}

.edits.visible {
  display: flex;
  opacity: 1;
}


/* In presentation mode, make the stroke white */
.edits.presentation-edits button svg path {
  stroke: #fff !important;
}

/* Edit Tools */
.edit-tools-container .edits {
  flex-direction: column;
  align-items: center;
}

/* Tool Buttons */
.edit-tools-container .edits button {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  height: 35px;
  width: 35px;
  font-size: 1.1rem;
  margin-bottom: 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  opacity: 0.80;
  transition: opacity 100ms ease-in-out;
}

.edit-tools-container .edits button:hover {
  opacity: 1;
}

.edit-tools-container .edits button.active {
  opacity: 1;
  /* Additional styling if needed */
}

/* Ensure SVG Icons are Visible */
.edit-tools-container svg {
  width: 24px;
  height: 24px;
  fill: #000000; /* Set fill color for visibility */
}

.edit-tools-container .edits button svg {
  width: 24px;
  height: 24px;
  fill: #000000; /* Set fill color for visibility */
}

.edit-tools-container .edits button.active svg {
  fill: lightgrey; /* Highlight active tool */
}



/* Color Choices */
.edit-tools-container .edits .color-choice {
  width: 24px !important;
  height: 24px !important;
  margin: 3px 0 !important;
  border: 2px solid lightgrey !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  transition: border 150ms ease-in-out, box-shadow 150ms ease-in-out !important;
}

.color-choice.selected {
  border: 3px solid #61dafb; /* Highlight selected color */
  box-shadow: 0 0 15px #61dafb; /* Glowing effect */
  background-color: rgba(97, 218, 251, 0.4); /* Contrast */
  transform: scale(1.3); /* Slightly larger */
  transition: all 0.2s ease-in-out; /* Smooth transition */
}

/* Cursor Styles */
.pen-cursor-blue {
  cursor: url('../../assets/pen-cursor-blue.svg') 24 24, auto !important;
}
.pen-cursor-yellow {
  cursor: url('../../assets/pen-cursor-yellow.svg') 24 24, auto !important;
}
.pen-cursor-green {
  cursor: url('../../assets/pen-cursor-green.svg') 24 24, auto !important;
}
.pen-cursor-red {
  cursor: url('../../assets/pen-cursor-red.svg') 24 24, auto !important;
}
.pen-cursor-black {
  cursor: url('../../assets/pen-cursor-black.svg') 24 24, auto !important;
}
.pen-cursor-white {
  cursor: url('../../assets/pen-cursor-white.svg') 24 24, auto !important;
}

.custom-cursor {
  cursor: grab;
}


/* Restart Icon */
.restart-icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none; /* Initially hidden */
  justify-content: center;
  align-items: center;
}

.restart-icon {
  width: 60px;
  height: 60px;
  fill: white;
  cursor: pointer;
  transition: transform 0.3s;
}

.restart-icon:hover {
  transform: scale(1.1);
}

.editor-container:not(.paused) .play-icon {
  display: none;
}

/* Loading Message */
.loading-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  background-color: #fff; /* Background color */
  position: relative;
  padding: 0 20px; /* Mobile responsiveness */
}

.loading-message-text {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #555; /* Text color */
  font-size: 1.5rem; /* Text size */
  font-weight: 500; /* Text weight */
  text-align: center;
  margin: 20px 0; /* Spacing */
  line-height: 1.5; /* Line spacing */
  max-width: 80%; /* Text width */
}

/* Surgeon Emoji Icons */
.surgeon-icons {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  gap: 10px; /* Space between icons */
  margin: 10px 0; /* Spacing */
}

/* Bacteria Animation */
.bacteria {
  display: inline-block;
  animation: bacteria-float 1.5s infinite;
}

@keyframes bacteria-float {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-10px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.surgeon-icons .bacteria:nth-child(1) {
  animation-delay: 0s;
}

.surgeon-icons .bacteria:nth-child(2) {
  animation-delay: 0.33s;
}

.surgeon-icons .bacteria:nth-child(3) {
  animation-delay: 0.66s;
}


/* Style the Info Card to overlay the video */
.info-card {
  position: absolute;
  top: 0px; /* Adjust as needed */
  left: 60px; /* Adjust as needed */
  width: 300px; /* Adjust width to fit the content */
  background-color: rgba(245, 245, 245, 0.90); /* Light background with opacity */
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family: 'Merriweather', serif;
  z-index: 10; /* Ensure it stays above the video */
  overflow-y: auto; /* Add scroll if content overflows */
  height:100%
}

/* When in full screen, remove the top offset */
:fullscreen .info-card,
:-webkit-full-screen .info-card {  /* for WebKit browsers */
  top: 0 !important;
}


.info-title {
  font-size: 1.2rem; /* Size of the title */
  font-weight: 700; /* Bold font */
  margin: 0 0 8px 0;
  color: #2c3e50; /* Text color */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 1px;
  text-align: left; /* Aligns the title to the left */
}


/* Style the description */
.info-description {
  font-size: 14px;
  margin-bottom: 16px;
  text-align: center;
}





/* Responsive adjustments */
@media (max-width: 768px) {
  .info-card {
    width: 90%;
    right: 5%;
    top: 10px;
    padding: 12px;
  }

  .info-title {
    font-size: 18px;
  }

}


.close-info-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.close-info-btn:hover {
  color: #000;
}

/* Separator styling */
.info-separator {
  margin: 16px 0;
  border: none;
  border-top: 1px solid #ccc;
}


.video-preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

/* Shimmer Effect */
.video-preloader::after {
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    #ddd 0%,  
    #eee 20%,  
    #ddd 40%,  
    #ddd 100%  
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  opacity: 0.6;
}

@keyframes shimmer {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}

/* Ensure it remains until loading completes */
.video-preloader.hidden {
  opacity: 0;
  height: auto; /* Keep height */
  pointer-events: none;
}



.loader-vp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.throttling-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; /* Ensure it appears above other content */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}






/* Color Button */
.color-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* Halo effect */
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.color-btn:hover {
  transform: scale(1.1);
}

/* Thickness Button */
.thickness-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* Halo effect */
  margin-top: 5px;
  margin-bottom: 5px;
}

.thickness-btn:hover {
  transform: scale(1.1);
}

/* Thickness Indicator */
.thickness-indicator {
  width: 60%; /* Keep the width consistent */
  border-radius: 4px; /* Slight rounding for a modern look */
  transition: height 0.15s ease; /* Smooth transition for thickness changes */
}

.present-mode {
  background-color: #333 !important;
}


/* Draw Button (Base Styling) */
.draw-btn, .arrow-btn, .circle-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-color: #d3d3d3; /* Default button background */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* Active Draw Mode */
.draw-btn.active, .arrow-btn.active, .circle-btn.active {
  box-shadow: 0 0 0 2px #b0b0b0; /* Light grey circle around the active button */
  transform: scale(1.05); /* Slightly scale to indicate it's active */
}




/* Bottom Info Container */
.bottom-info-container {
  width: 100%;
  height: 11vh; /* Fixed height as per your setup */
  background-color: lightgrey;
  padding: 10px 15px; /* Reduced padding to save space */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Prevent scrolling */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Ensure consistent font */
}

/* Info Content */
.info-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Left Panel: Title and Buttons */
.info-left-panel {
  flex: 1; /* 1/3 of the width */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info-title {
  font-size: 1.2rem; /* Increased font size for prominence */
  font-weight: 700; /* Bold */
  margin: 0 0 8px 0;
  color: #2c3e50;
  text-transform: uppercase; /* Adds a professional touch */
  letter-spacing: 1px;
}

/* General Button Styling */
.info-btn.gen-btn {
  position: relative;
  z-index: 50;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px; 
  transition: transform 0.2s ease-in-out;
  outline: none;
  width: 40px; 
  height: 40px; 
  margin-top: 60px;
}

.info-btn.gen-btn svg {
  width: 100%;  
  height: 100%;
}

.info-btn.gen-btn:hover {
  transform: scale(1.2); 
}

/* Button Group */
.info-buttons {
  display: flex;
  gap: 10px;
}

/* Buttons Styling */
.info-button {
  padding: 6px 12px;
  font-size: 0.9rem;
  font-weight: 500;
  color: #ffffff;
  background-color: #3498db;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.info-button.active,
.info-button:hover {
  background-color: #2980b9;
}

/* Right Panel: Content Sections */
.info-right-panel {
  flex: 2; /* 2/3 of the width */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px; /* Space between left and right panels */
}

/* Description Section */
.info-description {
  font-size: 0.95rem;
  color: #7f8c8d;
  line-height: 1.4;
  font-weight: 400; /* Normal weight */
}

/* Procedure Info Section */
.info-procedure {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px 20px;
  font-size: 0.9rem;
  color: #34495e;
  line-height: 1.4;
  font-weight: 400; /* Normal weight */
}

.info-procedure p {
  margin: 0;
  font-weight: 400; /* Ensure normal weight */
}

/* Override font-weight for all <p> within .info-card */
.info-card p {
  font-weight: 400; /* Normal weight */
}

/* Ensure <strong> tags within <p> remain bold */
.info-card p strong {
  font-weight: 700; /* Bold for labels */
}

/* Style for data text */
.info-data {
  font-weight: 14; /* Normal weight */
  color: rgb(104, 104, 104); /* Ensure consistent text color */
  margin-left: 5px; /* Optional: Space between label and data */
}

/* Style for data text */
.info-date {
  font-weight: 14; /* Normal weight */
  color: rgb(104, 104, 104); /* Ensure consistent text color */
  margin-left: 5px; /* Optional: Space between label and data */
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .info-title {
    font-size: 1rem;
  }

  .info-button {
    padding: 5px 10px;
    font-size: 0.85rem;
  }

  .info-procedure {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 8px 16px;
  }
}

@media (max-width: 768px) {
  .bottom-info-container {
    height: auto; /* Allow height to adjust on smaller screens */
    padding: 10px;
  }

  .info-content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .info-left-panel {
    flex: none;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
  }

  .info-right-panel {
    flex: none;
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
  }

  .info-procedure {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 5px 10px;
  }
}

/* Vertical Separator */
.separator {
  width: 2px;
  height: 60px; /* Adjust height as needed */
  background-color: black; /* Black color */
  margin-right: 20px; /* Add spacing between buttons and separator */
}


.main-container {
  display: flex;
  width: 100vw;
  height: calc(100vh - 60px); /* Subtract the header height */
  overflow: hidden;           /* No global scrolling */
}



/* Time Preview variations */
.timeline-preview {
  pointer-events: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  transform: translateX(-50%);
  bottom: 100%;
  margin-bottom: 8px;
  z-index: 5; /* Lowered from 100 to below the indicator's 10 */
  white-space: nowrap;
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease;

}

.timeline-preview.visible {
  display: block;
  opacity: 1;
}

/* Special styling for indicator preview */
.timeline-preview.indicator-preview {
  background-color: rgba(255, 99, 71, 0.9);
  box-shadow: 0 0 8px rgba(255, 99, 71, 0.5);
  color: #e0e0e0; /* Lighter base text color */
}

.timeline-preview.indicator-preview .preview-title {
  color: #ffffff; /* Bright white for better contrast */
  font-weight: 700; /* Bold weight for the title */
  letter-spacing: 0.5px; /* Slightly spread letters for emphasis */
}

/* Optional: Style the title inside the preview */
.preview-title {
  margin-left: 8px;
  font-weight: bold;
}

/* Ensure the timeline container has a relative position */
.timeline-container {
  position: relative;
}

/* 🔲 Modal Overlay */
.custom-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Slightly dimmed overlay */
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
}

/* 📌 Modal Box */
.custom-modal {
  background: #fff;
  color: #333;
  padding: 1.5rem;
  max-width: 380px;
  width: 90%; /* Mobile-friendly */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Subtle shadow */
  text-align: center;
  pointer-events: auto;
  font-family: "Inter", sans-serif;
}

/* 🔘 Button Group */
.modal-buttons {
  margin-top: 1.2rem;
  display: flex;
  gap: 0.8rem;
  justify-content: center;
}

/* ✅ Confirm Button */
.confirm-btn {
  background: #007bff;
  color: white;
  font-weight: 500;
  border: none;
  padding: 10px 18px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.95rem;
  transition: background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.confirm-btn:hover {
  background: #0056b3;
  box-shadow: 0 2px 8px rgba(0, 91, 187, 0.3);
}

.confirm-btn:active {
  background: #004494;
}

/* ❌ Cancel Button */
.cancel-btn {
  background: #f3f3f3;
  color: #333;
  font-weight: 500;
  border: 1px solid #ccc;
  padding: 10px 18px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.95rem;
  transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.cancel-btn:hover {
  background: #e0e0e0;
  border-color: #bbb;
}

.cancel-btn:active {
  background: #d6d6d6;
}

/* Prevent interaction with the rest of the UI */
.video-wrapper.overlay-active > *:not(.custom-overlay) {
  pointer-events: none;
}


/* Quality Selector Container */
.quality-selector {
  position: relative; 
  display: flex; /* Align the toggle button in line with others */
  align-items: center;
}

/* The Toggle Button (Quality) */
.quality-btn {
  background: none;
  border: none;
  color: inherit;
  font-size: 1rem;
  cursor: pointer;
  opacity: 0.85;
  transition: opacity 150ms ease-in-out;
  padding: 0;
  height: 30px;
  min-width: 40px; /* Enough width to show text like "720p" comfortably */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hover state for the button */
.quality-btn:hover {
  opacity: 1;
}

/* Drop-Up Container (shown only if isQualityOpen is true in React) */
.quality-dropdown {
  position: absolute;
  bottom: 100%; /* Pops up above the button */
  left: 50%;
  transform: translateX(-50%);
  background: rgba(30, 30, 30, 0.9);
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  min-width: 80px;
  text-align: center;
  z-index: 100;
  padding: 6px 0;
  /* No 'display: none' here; you conditionally render it in React */
}

/* Each Option in the Drop-Up */
.quality-option {
  color: #fff;
  cursor: pointer;
  padding: 6px 12px;
  transition: background 0.2s;
  font-size: 0.9rem;
}

/* Hover effect on an option */
.quality-option:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Highlight the currently active quality */
.quality-option.active {
  background: rgba(0, 150, 255, 0.5);
}

.toggle-slider {
  margin-top: 20px;
  width: 50px;
  height: 24px;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  transition: background 0.3s ease;
}

/* Presentation mode = dark track */
.toggle-slider.presentation {
  background: #333;
}

/* Edit mode = light track */
.toggle-slider.edit {
  background: #ddd;
}

/* The circle (same color in both modes) */
.slider-circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 3px;
  top: 3px;
  transition: transform 0.3s ease;
}

.toggle-slider.presentation .slider-circle {
  transform: translateX(26px);
}
.toggle-slider.edit .slider-circle {
  transform: translateX(0);
}

.toggle-slider.disabled {
  pointer-events: none; /* Prevent clicks */
  opacity: 0.5;         /* Visually indicate disabled state */
}

.focus-navigation {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  z-index: 101;
  pointer-events: none;
}

.focus-navigation button {
  pointer-events: auto;
  width: 80px;
  height: 80px;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.6;
  transition: transform 0.2s ease, opacity 0.2s ease, margin-right 0.3s ease;
}

/* Hover State */
.focus-navigation button:hover {
  opacity: 1;
  transform: scale(1.1);
}

/* Disabled Button: Grayed out + no pointer */
.focus-navigation button:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

/* SVG Icons: now 3× bigger (72×72) than your original 24×24 */
.focus-navigation button svg {
  fill: currentColor;
  width: 72px;
  height: 72px;
}



.video-transition-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000; /* Solid black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it covers underlying elements */
}

.minimal-loader {
  border: 4px solid rgba(255, 255, 255, 0.2); /* Light border for subtlety */
  border-top: 4px solid #fff; /* White segment for rotation effect */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Smooth, continuous rotation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}




/* Shimmer overlay that covers the annotation list */
.annotation-list-shimmer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10; /* Higher than the annotation list */
  background-color: inherit; /* Matches the container's background */
}

/* Shimmer effect element */
.annotation-list-shimmer {
  width: 100%;
  height: 100%;
  animation: shimmer 1.5s infinite;
  background: linear-gradient(
    to right,
    #f7f7f7 0%,
    #e0e0e0 50%,
    #f7f7f7 100%
  );
  background-size: 200% 100%;
  border-radius: 10px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}







.toggle-moments-btn.gen-btn {
  /* Make it a small rectangular button */
  width: 40px; 
  height: 36px; 
  padding: 0; 

  /* Remove circle; use a slight radius for a modern look */
  border-radius: 4px; 

  /* Keep color contrast: white text on a dark background */
  background: #444; 
  color: #fff;
  border: none; /* remove the border, rely on hover/active states for feedback */
  
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;

  /* Animations for hover/active states */
  transition: background 0.2s ease, transform 0.2s ease;
}

/* Subtle hover effect: darken the background, maybe scale a bit */
.toggle-moments-btn.gen-btn:hover {
  background: #555;
  transform: scale(1.05);
}

/* When the panel is open (active), invert colors to highlight */
.toggle-moments-btn.gen-btn.active {
  background: #fff;
  color: #333;
}

/* If you want more vertical spacing from your other buttons */
.toggle-moments-btn.gen-btn {
  margin-top: 60px; /* adjust as needed */
}


/* Moments Popup Overlay:
   – Positioned inside the editor-container as an overlay on top of the video
   – Left offset leaves room for the edit-tools container (assumed ~60px ≈ 6vw)
   – Right offset leaves a small margin (2vw)
   – Top offset (6vh) for non-fullscreen mode
   – Height is determined by content (or you can set a fixed height, e.g. 22vh)
*/
.moments-popup {
  position: absolute;
  top: 60px;
  left: 6vw;
  right: 2vw;
  /* Set a fixed height based on card height plus padding */
  height: 22vh;
  background-color: black;
  z-index: 95;
  padding: 1vh 1vw;
  box-sizing: border-box;
  border: 0.3vw solid #444;
  border-radius: 1vw;
}

/* Moments Row: Arranges the cards and the pagination column horizontally */
.moments-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1vw; /* gap between cards and pagination */
  height: 100%;
}

/* Moments Content: Contains the moment cards in a horizontal scroll row */
.moments-content {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  overflow-x: auto;
  flex: 1;
}

/* Moments Pagination: Fixed narrow column to the right */
.moments-pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5vw;
  width: 4vw; /* fixed width */
}

/* Pagination Buttons: using viewport units */
.pagination-btn {
  background: #ddd;
  border: none;
  padding: 0.5vh 0.5vw;
  cursor: pointer;
  border-radius: 0.5vw;
  width: 4vw;
  height: 3vh;
  transition: background 0.2s;
}

.pagination-btn:hover:not(:disabled) {
  background: #ccc;
}

.pagination-info {
  font-size: 1vh;
  color: #fff;
  text-align: center;
}

/* Moment Card: Each card takes up about 16vw; this allows five cards plus gaps and pagination */
.moment-card {
  background-color: black;
  border: 0.2vw solid #777;
  border-radius: 1vw;
  padding: 1vh 0.5vw;
  width: 16vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  transition: background-color 0.2s ease;
}

.moment-card:hover {
  background-color: #222;
}

.moment-card.active {
  border-color: #007bff;
  background-color: #333;
  box-shadow: 0 0 1vw rgba(0, 123, 255, 0.7);
}

/* Thumbnail: occupies full width at top of card */
.moment-thumbnail {
  width: 100%;
  height: 12vh;  /* thumbnail height relative to viewport height */
  overflow: hidden;
  border-radius: 0.5vw;
}

.moment-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Moment Info: text below the thumbnail */
.moment-info {
  width: 100%;
  padding: 0.5vh 0.5vw;
  text-align: center;
  box-sizing: border-box;
}

.moment-title {
  font-size: 1.5vh;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.moment-description {
  font-size: 1.2vh;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.moment-time {
  font-size: 1.1vh;
  margin: 0;
  color: #ccc;
}

/* Dummy (Skeleton) Styles for Preloading */
.moment-card.dummy {
  background-color: #333;
  border-color: #555;
  animation: shimmer 1.5s infinite;
}

.dummy-thumbnail,
.dummy-title,
.dummy-description,
.dummy-time {
  background-color: #666;
  border-radius: 0.5vw;
}

.dummy-thumbnail {
  width: 100%;
  height: 100%;
}

.dummy-title {
  width: 60%;
  height: 1vh;
  margin-bottom: 0.5vh;
}

.dummy-description {
  width: 80%;
  height: 0.8vh;
  margin-bottom: 0.5vh;
}

.dummy-time {
  width: 40%;
  height: 0.8vh;
}

/* Shimmer Animation */
@keyframes shimmer {
  0% {
    background-position: -10vw 0;
  }
  100% {
    background-position: 10vw 0;
  }
  
}

.editor-container.full-screen .moments-popup {
  top: 0 !important;
}