.auth0-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #fff;
    font-family: Arial, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
  }
  
  .auth0-loader-text {
    display: inline-block;
  }
  
  .auth0-dots {
    display: inline-block;
  }
  
  .auth0-dots span {
    opacity: 0;
    animation: fadeDots 1.5s infinite;
  }
  
  .auth0-dots span:nth-child(1) { animation-delay: 0s; }
  .auth0-dots span:nth-child(2) { animation-delay: 0.3s; }
  .auth0-dots span:nth-child(3) { animation-delay: 0.6s; }
  
  @keyframes fadeDots {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
  }