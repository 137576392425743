/* Container */
.upload-editor-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh; /* Utilize full viewport height */
    padding: 40px 20px;
    background-color: #f0f2f5;
}

/* Title */
.upload-title {
    margin-bottom: 10px;
    font-size: 2.5rem;
    color: #333;
    text-align: center;
}

/* Subtitle */
.file-format-subtitle {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 1.2rem;
    color: #666;
    text-align: center;
}

/* Form */
.upload-video-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px; /* Increased max-width for better utilization */
    background: white;
    padding: 30px 40px;
    border-radius: 8px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px; /* Increased margin-bottom for spacing */
}

/* Form Group */
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px; /* Increased spacing between form groups */
}

/* Labels */
.form-group label {
    margin-bottom: 8px;
    font-size: 1rem;
    color: #333;
    font-weight: 500;
}

/* Required Asterisk */
.required {
    color: red;
    margin-left: 4px;
}

/* Inputs, Textareas, Selects */
.upload-video-form input[type="text"],
.upload-video-form input[type="file"],
.upload-video-form textarea,
.upload-video-form select {
    padding: 12px 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.upload-video-form input[type="text"]:focus,
.upload-video-form input[type="file"]:focus,
.upload-video-form textarea:focus,
.upload-video-form select:focus {
    border-color: #007BFF;
    outline: none;
}

/* Textarea Specific */
.upload-video-form textarea {
    resize: vertical;
    min-height: 100px;
}

/* AsyncSelect Container */
.form-group .react-select__control {
    padding: 2px;
}

.form-group .react-select__control--is-focused {
    border-color: #007BFF;
    box-shadow: 0 0 0 1px #007BFF;
}

/* Submit Button */
.upload-submit-button {
    padding: 14px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
}

.upload-submit-button:disabled {
    background-color: #a0c5f7;
    cursor: not-allowed;
}

.upload-submit-button:hover:not(:disabled) {
    background-color: #0056b3;
}

/* Upload Progress */
.upload-progress {
    width: 100%;
    max-width: 600px; /* Align progress bar width with the form */
    background-color: #e9ecef;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 20px; /* Spacing above progress bar */
}

.progress-bar {
    height: 28px; /* Increased height for better visibility */
    background-color: #28a745;
    transition: width 0.4s ease;
}

.upload-progress p {
    text-align: center;
    margin: 10px 0 0 0;
    font-size: 1rem;
    color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
    .upload-video-form {
        padding: 20px;
        max-width: 100%;
    }

    .upload-progress {
        max-width: 100%;
    }
}


.upload-success-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px; /* Adjust as needed */
    text-align: center;
    font-size: 1.5em;
    color: #28a745; /* Green color to indicate success */
    background-color: #e6ffe6; /* Light green background */
    border: 1px solid #28a745;
    border-radius: 10px;
    margin-top: 30px;
}


.upload-type-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 10px;
}

.upload-type-label {
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    padding: 10px 15px;
    color: #444;
    transition: background-color 0.2s ease, border 0.2s ease, padding 0.2s ease;
}

/* Default state: No box */
.upload-type-label span {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 6px;
    transition: all 0.2s ease;
}

/* Hover effect */
.upload-type-label:hover span {
    background-color: #f2f2f2;
    border: 2px solid #ccc;
}

/* Checked state */
.upload-type-label input:checked + span {
    background-color: #e0e0e0;
    border: 2px solid #444;
    font-weight: bold;
    color: #222;
}

/* Hide default radio button */
.upload-type-label input {
    display: none;
}

/* Description text */
.upload-type-description {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    color: #555;
    margin-top: 8px;
    min-height: 24px; /* Ensures space is reserved when switching */
}

/* Container for the file list with a fixed height and scrollable */
.file-list-container {
    max-height: 250px;  /* Adjust the fixed height as needed */
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 8px;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #fafafa;
  }
  
  /* Each file item */
  .file-item {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    margin-bottom: 6px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
  }
  
  /* Left controls: up/down buttons */
  .order-controls {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
  }
  
  .order-controls button {
    background: none;
    border: none;
    padding: 2px;
    cursor: pointer;
    font-size: 1rem;
    color: #555;
  }
  
  .order-controls button:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  
  /* Ordering number */
  .file-order {
    width: 25px;
    text-align: right;
    margin-right: 8px;
    font-weight: bold;
    color: #333;
  }
  
  /* File name */
  .file-name {
    flex-grow: 1;
    font-size: 0.95rem;
    color: #444;
  }
  
  /* Delete button */
  .delete-button {
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #d00;
    cursor: pointer;
    margin-left: 8px;
  }

  .disabled-upload {
    position: relative;
    cursor: not-allowed;
    opacity: 0.5;
}

.disabled-upload::after {
    content: "Coming Soon!";
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background: #333;
    color: white;
    padding: 5px 8px;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    white-space: nowrap;
}

.disabled-upload:hover::after {
    opacity: 1;
}

