/* Base Styles */
*, *::before, *::after {
    box-sizing: border-box;
  }


html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
}

.main-content {
    flex: 1; /* Allows it to grow and fill available space */
    display: flex;
    flex-direction: column;
    width: 100%; /* Ensures full width */
}



body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #F0EDEE;
    color: black;
    overflow-x: hidden;
    

}

.App {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Ensures children take full width */
    width: 100%;
    height: 100%; /* Full height as set in html, body, #root */
}

/* Header */
.header {
    width: 100%;
    background-color: #F0EDEE;
    padding: 1rem 2rem; /* Top/Bottom: 1rem, Left/Right: 2rem */
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
    font-family: 'Merriweather', serif;
    font-size: 1.1rem;
    height: 60px; /* Fixed height */
    box-sizing: border-box; /* Ensure padding is included in height */
}

.header .logo {
    font-size: 1.8rem;
    font-weight: bold;
    color: black;
    cursor: pointer; /* Add this line to change cursor on hover */
    /* a better typograhpy */
    text-decoration: none;
    font-family: 'Merriweather', serif;
    font-weight: 600;
}

.header .nav {
    display: flex;
    gap: 1.5rem;
}

.header .nav-link {
    text-decoration: none;
    color: black;
    font-weight: 500;
    transition: color 0.3s, background-color 0.3s;
    padding: 0.5rem 1rem;
    border-radius: 4px;
}

.header .nav-link:hover {
    color: black;
}

.footer {
    width: 100%;
    padding: 1rem;
    background-color: #F0EDEE;
    color: black;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    margin-top: auto; /* Pushes footer to the bottom */
    font-family: 'Merriweather', serif;
    
    /* Flexbox Layout */
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers child elements horizontally */
}

.contact-info {
    width: 100%;
    background-color: #F0EDEE;
    color: black;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-top: 1px solid #f8f8f8;
    
    /* Ensure it's centered within the footer */
    margin-top: 1rem; /* Adds spacing between the copyright and contact info */
}

.contact-item {
    flex: 1;
    text-align: center;
    padding: 0 1rem;
    border-left: 1px solid #f8f8f8;
}

.contact-item:first-child {
    border-left: none;
}

.contact-item p {
    margin: 0.5rem 0;
    font-family: 'Merriweather', serif;
    font-weight: 400;
    font-size: 1rem;
}


.logout-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    margin-left: 15px;
}

.logout-button:hover {
    text-decoration: underline;
}

.login-button {
    background: none;
    border: none;
    color: black;
    font-size: 1.1rem; /* Same size as nav links */
    font-weight: 500;
    font-family: 'Merriweather', serif;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    padding: 0.5rem 1rem; /* Match nav-link padding */
    text-decoration: none;
}

.login-button:hover {
    color: #666; /* Soft gray on hover, keeping it subtle */
}

.login-button:active {
    color: #444; /* Slightly darker on click */
}



/* Notification Styles */
.notification {
    position: fixed;
    top: 10vh;
    right: 20px;
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: fadeInOut 3s ease;
}

@keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    10%, 90% { opacity: 1; }
}


