/*********************************
 * LAYOUT & BASE CONTAINERS
 *********************************/

/* Main container below the header */
.ch-cases-home-container {
    /* Fill the space below the 60px header */
    display: flex;
    overflow: hidden; /* No scrolling for the entire container */
    background-color: #f8f8f8;
    position:relative;


  }


.group-sidebar {
    display: flex;
    flex-direction: column;
    width: 6.5vw; /* or 7.5vw */
    height: 100%;
    min-height: calc(100vh - 60px);
    gap:0.3rem;
    background-color: #f0f0f0;
    box-sizing: border-box;
  }
  
  /* Where pinned groups live (no scrolling) */
  .pinned-groups-container {
    flex-shrink: 0; /* so it won't shrink if the sidebar is small */
    width: 100%;
  }
  .pinned-groups .group-container:first-child {
    margin-top: 0.75rem; /* Adjust the value as needed */
  }
  /* The pinned groups themselves */
  .pinned-groups {
    
       display: flex;
       flex-direction: column;
       align-items: center; 

  }
  
  /* A horizontal divider if you want to separate pinned from real groups */
  .group-sidebar-divider {
    height: 2px;
    background-color: #888;
    margin: 0.5rem auto; 
    width: 80%; 
  }
  
  /* The container for your real groups area: 
     - "flex: 1" so it takes up remaining vertical space.
     - "overflow: hidden" plus "min-height: 0" are crucial for scrolling. */
  .realgroups-outer {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0; /* needed so child can shrink and overflow */
    overflow: hidden;
    padding-left:11px;
  }
  
  /* The actual scrolling container */
  .realgroups-scrollable {
    flex: 1;
    overflow-y: auto; /* or scroll to force a scrollbar always visible */
    scrollbar-gutter: stable; /* reserves space for scrollbar so content doesn't shift */
    scroll-snap-type: y mandatory;
  }
  
  /* Example custom scrollbar for WebKit */
  .realgroups-scrollable::-webkit-scrollbar {
    width: 6px;
  }
  .realgroups-scrollable::-webkit-scrollbar-track {
    background: transparent;
  }
  .realgroups-scrollable::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  
  /* For Firefox, you can do: */
  .realgroups-scrollable {
    scrollbar-width: thin;
    scrollbar-color: rgba(0,0,0,0.2) transparent;
  }
  



/* Group Container */
.group-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.1rem; /* Space between circle and name */
}

.group-container:hover .group-circle {
  background-color: #999; /* Change circle color to indicate hover */
  transition: background-color 0.2s ease; /* Smooth transition */
}

/* Name Styling */
.group-name {
  font-size: 0.7rem; /* Match the tooltip font size */
  color: #333; /* Text color */
  text-align: center;
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflow */
  max-width: 4rem; /* Restrict width to prevent overly long names */
  font-weight: bold; /* Make the text bold */
  margin-bottom: 1.2rem;
}
/* Circle Styling */
.group-circle {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #ccc; /* Default circle color */
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s ease;
}


.custom-group-circle {
  width: 2.5rem; /* Default size for all group circles */
  height: 2.5rem;
  border-radius: 50%; /* Make it a circle */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* Text color */
  font-weight: bold;
  background-color: #800020; /* Burgundy color */
  cursor: pointer; /* Indicate interactivity */
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out; /* Smooth hover effect */
}


.group-circle:hover {
  background-color: #999;
}
  .group-circle::after {
    content: attr(data-label);    /* Pulls from data-label="All Videos" etc. */
    position: absolute;
    left: 50%;
    bottom: calc(100% + 5px);     /* Slightly above the circle */
    transform: translateX(-50%);  /* Centers horizontally */
    padding: 6px 10px;
    font-size: 0.8rem;
    font-weight: normal;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;                   /* Start hidden */
    pointer-events: none;
    transition: opacity 0.2s ease;
    z-index: 10;                  /* Ensure above other elements */
  }

/* Show tooltip on hover, if there's a data-label 
.group-circle:hover::after {
  opacity: 1;
}*/


.group-hover-tooltip {
  position: absolute;
  transform: translateY(-50%); /* vertical centering around the hovered element's midpoint */
  padding: 6px 10px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 999; /* make sure it appears on top */
  pointer-events: none; /* so it doesn't interfere with the user's cursor */
  font-size: 0.8rem; /* adjust as needed */
}

  /* Specific circle colors */
  .all-circle,
  .uploads-circle,
  .annotated-circle,
  .starred-circle { background-color: var(--primary-dark); }
  
  /* Main content to the right of the sidebar */
  .ch-cases-home-content {
    flex: 1;
    display: absolute;
    flex-direction: column;
    position: relative; /* For absolute-positioned elements (e.g., pagination) */
    padding: 1rem;
    box-sizing: border-box;
  }
  
  /*********************************
   * SEARCH + PAGINATION (TOP BAR)
   *********************************/
  .top-search-pagination-bar {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Search on left, pagination on right */
    margin-bottom: 1rem;
    gap: 1rem;
  }
  
  /* Smaller integrated search field */
  .search-wrapper {
    margin-right: auto;  /* Push it to the left */
    display: flex;
    position: relative;
    width: 70vw; /* Adjust as needed to shrink or expand the search bar */
    height: 5vh;
  }
  
  .search-input-field {
    flex: 1;
    font-size: 1.3rem;
    padding: 0.4rem 2.2rem 0.4rem 0.8rem; /* space on the right for the icon button */
    border: 1px solid #ccc;
    border-radius: 25px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  .search-input-field:focus {
    border-color: var(--primary-dark);
  }
  
  /* The search icon button inside the input */
  .search-icon-button {
    position: absolute;
    top: 50%;
    right: 0.6rem;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search-icon-button:hover {
    color: var(--primary);
  }
  
  /* Pagination container on the right side */
/* Pagination container */
.pagination-bar {
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Adjust spacing as needed */
  }
  
  /* Arrow Buttons (<, >) */
  .arrow-button {
    width: 2rem; /* Fixed width */
    height: 2rem; /* Fixed height */
    background-color: var(--primary);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
  }
  .arrow-button:hover {
    background-color: var(--primary-dark);
  }
  .arrow-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Page Buttons */
  .page-button {
    width: 2rem; /* Fixed width */
    height: 2rem; /* Fixed height */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  .page-button:hover {
    background-color: #e2e2e2;
  }
  .page-button.active {
    background-color: var(--primary);
    color: white;
    border-color: var(--primary-dark);
  }
  
  /* Ellipsis (...) */
  .pagination-dots {
    width: 2rem; /* Same fixed width as buttons */
    height: 2rem; /* Same fixed height as buttons */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    color: #666;
    border: 1px solid #ccc;
    border-radius: 4px;
    pointer-events: none; /* Non-clickable */
  }
  /*********************************
   * VIDEO GRID + CARDS
   *********************************/
  
  /* The video grid: 2 rows × 3 columns, no scrolling */
  .ch-cases-home-video-grid {
    flex: 1; 
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1vw;

    align-items: center;
    justify-items: center;
    position: relative;
    box-sizing: border-box;
    overflow: hidden; /* Prevent scrolling */
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 1vw;
    min-height: 60%;
  }
  
  /* Linking style for each video card */
  .ch-editor-container-link {
    display: block;        /* So it strictly wraps the container */
    width: 100%;           /* Fill the grid cell horizontally */
    height: 100%;          /* Fill the grid cell vertically */
    text-decoration: none; /* No underline, etc. */
    color: inherit;        /* Inherits text color from parent */
    position: relative;    /* If needed to ensure no extra clickable region */
    pointer-events: auto;  /* We do want the link clickable inside the container */
  }
  
  /* Video Card Container */
.ch-editor-container {
  height:100%;
  margin: 0;
  width: 100%; /* Fill link container horizontally */
  position: relative; /* Ensures button can be positioned relative to the card */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  min-height: 0; /* Ensure card has a minimum height */
}




  .ch-editor-container:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
/* Parent container must be position: relative to host an absolutely positioned child */
.ch-video-thumbnail {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background-color: #000; /* Black background as a fallback */
  overflow: hidden;
}
/* The bubble that overlays the thumbnail */
.info-bubble {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* White or slightly transparent white background */
  background-color: rgba(255, 255, 255, 0.92);

  /* Text styling */
  color: #333;
  padding: 1rem;

  /* Start hidden */
  opacity: 0;
  pointer-events: none; /* So it doesn't block the hover from leaving the card */
  transition: opacity 0.2s ease;
  z-index: 5;

  /* Optionally center the text */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertical center */
  align-items: center;     /* Horizontal center */
  text-align: center;
}

/* Show the bubble on hover over the .ch-video-thumbnail area */
.ch-video-thumbnail:hover .info-bubble {
  opacity: 1;
}



.thumbnail-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f0f0f0;
  color: #888;
  font-size: 1rem;
  text-align: center;
  visibility: visible; /* Ensure placeholder is visible when image is not loaded */
  opacity: 1;
  transition: opacity 0.3s ease;
}
.thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}
.thumbnail-image:not([src]) {
  opacity: 0;
}

.thumbnail-image[src] + .thumbnail-placeholder {
  opacity: 0;
  visibility: hidden;
}



  /* Text Container: ~1/3 height, title + footer row */
  .ch-text-container {
    flex: 1; 
    display: flex;
    flex-direction: column;
    padding: 0.8vw;
    justify-content: space-between; 
  }
  
  /* Title: up to 2 lines with ellipsis */
  .video-title {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  
  /* Footer row: surgeon + date */
  .video-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem; 
    color: #555; 
    width: 100%;
  }
  .video-footer-spacer {
    flex: 1;
  }
  .upload-date {
    color: #666;
    font-size: 0.9rem;
  }
  
  /*********************************
   * EMPTY STATES, LOADER, ETC.
   *********************************/
  
  .no-videos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    text-align: center;
    background-color: #f8f8f8;
    border-radius: 10px;
    grid-column: 1 / -1;
    min-height: 200px; 
  }
  .no-videos-icon {
    font-size: 50px;
    margin-bottom: 10px;
    color: #bbb;
  }
  .no-videos-text {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  .no-videos-suggestion {
    font-size: 16px;
    color: #777;
  }

  /*********************************
   * ROOT VARIABLES & ADDITIONAL
   *********************************/
  
  :root {
    --primary: #003366;       /* Navy Blue */
    --primary-dark: #002244;  /* Darker Navy */
    --primary-darker: #001122;
  
    --secondary: #eff6fc;     /* Very Light Blue */
    --secondary-dark: #A3C4FF;
  
    --background-light: #eff6fd;
    --background-alice: #F0EDEE;
  
    --text-dark: #333333;
  
    --button-hover: #002244;
    --button-active: #001122;
  }
  
  /* Surgeon name styling (inside .video-footer) */
  .surgeon-name {
    font-weight: 600;
    color: #333;
    background-color: transparent; 
    padding: 0; 
    border-radius: 0;
  }
  
  /* Optionally show a delete button inside the card on hover (if used) */
  .ch-delete-button {
    opacity: 0;
    transition: opacity 0.3s ease;
    position: absolute;
    right: 1.5vw;
    top: 1.5vw;
    background: none;
    border: none;
    font-size: 1.1vw;
    cursor: pointer;
    color: red; 
    z-index: 1;
  }
  .ch-editor-container:hover .ch-delete-button {
    opacity: 1;
  }
  .ch-delete-button:hover {
    color: rgba(255, 0, 0, 0.644);
  }


  /* Upload Icon Button */
.upload-icon-button {
  background: white; /* Initial white background */
  border: 2px solid var(--primary-dark);
  /* Blue border for visibility */
  border-radius: 50%; /* Circular button */
  cursor: pointer;
  padding: 0.4rem; /* Smaller padding for a more compact button */
  width: 3rem; /* Define a fixed width */
  height: 3rem; /* Define a fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem; /* Add margin to the left for spacing */
  transition: background-color 0.3s ease, transform 0.3s ease, border-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for emphasis */
}

/* Icon within the button */
.upload-video-icon {
  width: 1.4rem; /* Adjusted size of the icon */
  height: auto; /* Maintain aspect ratio */
  fill: #002244; /* Darker blue icon color for better visibility */
  transition: fill 0.3s ease;
}
/* Hover Effect */
.upload-icon-button:hover {
  border-color: #32CD32; /* Darker border on hover */
  transform: scale(1.1); /* Slight enlargement on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.upload-icon-button:hover .upload-video-icon {
  fill: var(--primary); /* Standard blue icon on hover */
}




/* Delete button in the footer */
.delete-button-footer {
  background-color: #d9534f; /* Bootstrap "danger" red */
  border: none;
  color: white; /* White text on red background */
  font-size: 0.8rem; /* Compact size for subtlety */
  font-weight: bold;
  padding: 4px 8px; /* Small padding for a compact look */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-left: 10px; /* Space from upload date */
}

.delete-button-footer:hover {
  background-color: #c9302c; /* Darker red on hover */
  transform: scale(1.05); /* Slight enlargement on hover */
}

.delete-button-footer:active {
  background-color: #ac2925; /* Even darker red for active click */
  transform: scale(0.95); /* Slight shrink on click */
}



/* Placeholder styles for groups in the sidebar */
.preloader-circle {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #ddd;
  position: relative;
  overflow: hidden;
}

.preloader-circle::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 1.5s infinite;
}

.group-name-placeholder {
  width: 4rem;
  height: 1rem;
  background-color: #ddd;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.group-name-placeholder::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 1.5s infinite;
}

.preloader-group-container {
  margin-bottom: 1rem; /* Adds spacing between preloaders */
}

.preloader-group-container:last-child {
  margin-bottom: 0; /* Removes margin from the last preloader */
}


/* Placeholder styles for groups in the sidebar */
.preloader-circle {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #ddd;
  position: relative;
  overflow: hidden;
}

.preloader-circle::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 1.5s infinite;
}

.group-name-placeholder {
  width: 4rem;
  height: 1rem;
  background-color: #ddd;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.group-name-placeholder::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 1.5s infinite;
}



.ch-loader-wrapper {
  position: absolute; 
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%); 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:1vh;

}

.ch-loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ch-spin 1s linear infinite;
}

@keyframes ch-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Replace your .delete-button-footer with a .star-button-footer */
.star-button-footer {
  background-color: transparent;
  border: 2px solid #FFD700; /* Gold outline */
  border-radius: 50%;
  color: #FFD700;            /* Gold star symbol */
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover effect: fill with gold, white star symbol */
.star-button-footer:hover {
  background-color: #FFD700;
  color: #fff;
  transform: scale(1.1);
}

/* 'active' means the video is starred; keep it gold-filled */
.star-button-footer.active {
  background-color: #FFD700;
  color: #fff;
}

/* optional: if you want a special hover for active */
.star-button-footer.active:hover {
  background-color: #ffc500;  /* a slightly different gold? */
}

.no-groups-message {
  font-size: 0.65rem;      /* small font to fit the narrow sidebar */
  color: #888;
  text-align: center;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}