/* Import Fonts */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700&family=Roboto:wght@400&display=swap');

/* Global Styles */
* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Roboto', sans-serif; /* Default font for body */
}

.container {
  width: 100%;
  overflow-x: hidden; /* Hide horizontal overflow */
  /* Subtle gradient from light gray to desaturated blue-gray */
  background: linear-gradient(135deg, #f0f0f0 25%, #e0e0e0 100%);
}

/* Title Section */
.title-section {
  text-align: center;
  padding: 20px 20px; /* Increased padding for spaciousness */
  /* Light gradient background */
  background: linear-gradient(180deg, rgba(249, 249, 249, 0.8), rgba(255, 255, 255, 0.8));
}

.title-section h1 {
  font-family: 'Merriweather', serif; /* Serif font for elegance */
  font-size: 3rem; /* Increased font size */
  font-weight: 700; /* Bold weight */
  margin-bottom: 20px;
  color: #222; /* Darker color for contrast */
  line-height: 1.2; /* Improved readability */
  letter-spacing: -1px; /* Slightly tighter letters */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  /* Gradient Text */
  background: linear-gradient(90deg, #2c2f33 , #5a5f67);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.title-section h1::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background-color: #8a9aa7; /* Use matching accent color */
  margin: 10px auto 0;
  border-radius: 2px;
}

.title-section h3 {
  font-family: 'Roboto', sans-serif; /* Sans-serif for clarity */
  font-size: 1.5rem; /* Adjusted for hierarchy */
  font-weight: 400; /* Normal weight */
  color: #555; /* Softer color for subtitles */
  line-height: 1.5;
  letter-spacing: 0.5px; /* Slightly increased spacing */
  margin-bottom: 10px;
}

/* Separator */
.separator-home {
  width: 100%;
  height: 1px;
  margin: 20px 0;
  /* Subtle gradient for separator */
  background: linear-gradient(to right, rgba(200, 200, 200, 0.3), rgba(160, 170, 180, 0.7), rgba(200, 200, 200, 0.3));
}

/* Annotation Tools Section */
.annotation-tools-section {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.annotation-text {
  flex: 0 0 25%;
  padding: 0 20px; /* Add horizontal padding */
  text-align: left;
}

.annotation-text h2 {
  font-size: 2rem; /* Adjusted font size */
  font-weight: bold;
  color: #333;
  margin: 0;
}

.annotation-image {
  flex: 0 0 75%;
  display: flex;
  justify-content: center;
  padding-right: 20px; /* Add padding to separate from the edge */
  position: relative; /* For gradient overlay */
}

.annotation-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 75vw;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.tool-screenshot {
  width: 75vw; /* Required image width */
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
  position: relative;
  z-index: 1;
}

.annotation-image::before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 56.25%; /* For 16:9 aspect ratio */
  background-color: #e0e0e0; /* Placeholder color */
}


/* Gradient Overlay on Screenshot */
.annotation-image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 75vw;
  height: 100%;
  /* Light gradient overlay */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  border-radius: 10px;
  z-index: 2;
  pointer-events: none; /* Allows clicks through the overlay */
}

/* Content Section */
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background-color: #f5f5f5; /* Changed from white to pale gray */
  padding: 50px 0;
  width: 100%;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px;
}

.icon-container {
  background: black;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.icon {
  fill: white;
  width: 40px;
  height: 40px;
}

.icon-container:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.text {
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
  margin-top: 10px;
  transition: color 0.3s ease;
  max-width: 200px;
}

.feature:hover .text {
  color: rgb(57, 56, 56);
}

/* Contact Section */
.contact-section {
  /* Subtle diagonal gradient */
  background: linear-gradient(135deg, #f2f2f2, #e0e0e0);
  padding: 50px 10%;
  text-align: center;
}

.contact-section h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
}

.contact-form label {
  display: block;
  text-align: left;
  margin-bottom: 15px;
  color: #555;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form textarea {
  resize: vertical;
  min-height: 100px;
}

.contact-form button {
  background-color: #333;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.contact-form button:hover {
  background-color: #555;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .annotation-tools-section {
    flex-wrap: wrap;
  }

  .annotation-text,
  .annotation-image {
    flex: 1 1 100%;
  }

  .annotation-text {
    text-align: center;
    padding: 0 10px;
    margin-bottom: 20px;
  }

  .annotation-image {
    justify-content: center;
    padding-right: 0;
  }

  .tool-screenshot {
    width: 90vw;
    max-height: none;
  }

  /* Adjust container gradient for smaller screens */
  .container {
    background: linear-gradient(135deg, #f0f0f0 25%, #e0e0e0 100%);
  }
}

@media (max-width: 768px) {
  .title-section h1 {
    font-size: 2rem;
    letter-spacing: -0.5px;
  }

  .title-section h3 {
    font-size: 1rem;
    letter-spacing: 0.3px;
  }

  .annotation-text h2 {
    font-size: 1.8rem;
  }

  .tool-screenshot {
    width: 90vw;
  }
}

/* Annotation Image Section */
.annotation-image img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures proper scaling */
  opacity: 0; /* Initially hidden */
  transition: opacity 005.s ease-in-out; /* Longer, subtle fade-in */
}

.annotation-image img.loaded {
  opacity: 1; /* Fully visible once loaded */
}
