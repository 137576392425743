/* File: account.css */

/* 1) Main Page Layout */
.account-page {
  display: flex;
  flex-direction: column; /* Change to column layout */
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background: none; /* Site's global background shows */
}

/* Top Section with User Info and Groups */
.account-top-section {
  display: flex;
  width: 100%;
  gap: 2rem;
  padding: 20px;
  box-sizing: border-box;
}

/* Left Column: Account Info */
.account-left {
  width: 30%;
  /* Remove padding as it's now handled by the parent */
}

/* Adjusted User Info Card — not too tall now */
.user-info-card {
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  /* Reduced min-height so it doesn't overshadow the groups */
  height: 375px;
}
.avatar-placeholder {
  width: 60px;
  height: 60px;
  background-color: #800020;
  color: #fff;
  border-radius: 50%;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.user-name {
  font-size: 18px;
  color: #002244;
  margin-bottom: 0.3rem;
}
.user-meta {
  font-size: 14px;
  color: #444;
  margin-bottom: 1rem;
}
.account-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.account-links button {
  background-color: #002244;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
}
.account-links button:hover {
  background-color: #00122a;
}

/* 2) Right Column: Group Panels */
.account-right {
  width: 70%;
  /* Remove padding as it's now handled by the parent */
}
.error {
  color: #d9534f;
  font-weight: 500;
  margin-bottom: 1rem;
}

/* Dual layout for group panels */
.groups-dual-layout {
  display: flex;
  gap: 1rem;
}

/* Panels for groups */
.owned-groups-panel,
.member-groups-panel {
  flex: 1;
  background-color: #fff;
  border-radius: 6px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: 375px; /* Ensure both panels have the same height */
  display: flex;
  flex-direction: column; /* Aligns content vertically */
}

/* Panel header */
.panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.panel-header h2 {
  font-size: 18px;
  color: #002244;
  margin: 0;
}

/* Owned Groups: Add Group Button */
.add-group-button {
  background-color: #800020;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.add-group-button:hover {
  background-color: #600018;
}

/* 3) Group List Wrapper */
.group-list-wrapper {
  max-height: 300px; /* Adjust to show about 3-4 items */
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  background-color: #fafafa;
}
.group-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.group-list li + li {
  margin-top: 8px;
}

/* 4) Shared Group Item Styles */
.acc-group-item {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  transition: background-color 0.2s;
  margin-bottom: 4px;
}
.acc-group-item:hover {
  background-color: #f8f8f8;
}

/* Owned Group Specific Styles */
.acc-group-item.owned-group {
  /* Increase border thickness for clarity */
  border: 1.5px solid #ccc;
}
.acc-group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* Default truncated group title */
.acc-group-name.truncated {
  font-weight: 700;
  color: #002244;
  font-size: 16px;
  max-width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Expanded group title that allows wrapping */
.acc-group-name.expanded {
  font-weight: 700;
  color: #002244;
  font-size: 16px;
  max-width: 25vw;
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  word-break: break-word; /* ensures long text wraps nicely */
}

/* If you want to style the toggle button (blue text) */
.read-toggle {
  color: blue;
  cursor: pointer;
  margin-left: 5px;
  font-size: 12px;
}
.owner-tag {
  /* Removed for owned groups as per requirements */
  display: none;
}
.acc-group-description {
  margin: 6px 0;
  font-size: 14px;
  color: #555;
  line-height: 1.4;
  max-width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Override for expanded state */
.acc-group-description.expanded {
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  /* Allow long words to break */
  word-break: break-all;
  /* Alternatively: overflow-wrap: break-word; */
}


/* 5) Group Actions — Button Styling */
/* Shared styling for action buttons */
.acc-group-actions {
  display: flex;
  gap: 6px;
  margin-top: 6px;
}
.acc-group-actions button {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

/* For owned groups, show three buttons: Edit, Members, Delete */
.group-edit-button,
.group-manage-members-button,
.group-delete-button {
  background-color: #f0f0f0;
  color: #002244;
}


.group-edit-button:hover,
.group-manage-members-button:hover {
  background-color: #007bff;
  border-color: #002244;
  color: #fff;
}
.group-delete-button:hover {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}


/* For member (non‑owned) groups, only the View Members button is shown */
.view-members-button {
  background-color: #f0f0f0;
  color: #002244;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}
.view-members-button:hover {
  background-color: #002244;
  border-color: #002244;
  color: #fff;
}

/* 6) Upload Progress */
.upload-progress {
  margin-top: 16px;
  font-size: 14px;
  color: #333;
}
.progress-bar {
  height: 8px;
  background-color: #28a745;
  border-radius: 4px;
  transition: width 0.3s ease;
}

/* 7) Modals & Buttons (Restored from original) */
.modal {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  width: 95%;
  max-width: 700px;
  max-height: 85vh;
  overflow-y: auto; /* Only vertical scrolling inside modal content */
  position: relative; /* Ensure stacking context for dropdown */
  z-index: 1001; /* Ensure it appears above modal background */
}

.modal-content h3 {
  margin-top: 0;
  color: #333;
}
/* Increase group name input size */
.modal-content input#groupNameInput {
  font-size: 16px;
  padding: 12px; /* More padding for a larger input box */
  border-radius: 6px; /* Slightly larger border radius */
  width: 100%; /* Make sure it spans the full modal width */
  border: 1px solid #ccc;
}

/* Increase description textarea size */
.modal-content textarea#groupDescriptionInput {
  font-size: 16px;
  padding: 12px;
  border-radius: 6px;
  width: 100%;
  height: 150px; /* Previously standard height, now taller */
  resize: vertical; /* Allow resizing vertically if needed */
  border: 1px solid #ccc;
}


.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

/* Save Button */
.save-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.save-button:hover {
  background-color: #218838;
}

/* Cancel Button */
.cancel-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.cancel-button:hover {
  background-color: #5a6268;
}

/* Close Button */
.close-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.close-button:hover {
  background-color: #5a6268;
}

/* 8) Manage Members Modal – Member List */
.member-list {
  list-style-type: none;
  padding: 0;
}
.member-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  font-size: 14px;
  color: #333;
}
.remove-member-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.remove-member-button:hover {
  background-color: #c82333;
}

/* Input subtitles for edit modal */
.input-subtitle {
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

.no-scroll {
  overflow: hidden; /* Prevent scrolling */
  height: 100%; /* Ensure no accidental scrollbars appear */
}

.input-field {
  width: 100%;
  font-size: 16px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 16px;
}

.textarea-field {
  height: 150px; /* Taller for multiline input */
  resize: vertical; /* Allow vertical resizing */
}

/* Owner-specific styling */
.owner-item {
  margin-bottom: 10px; /* Add spacing between owner and other members */
  border-bottom: 1px solid #ddd; /* Optional: underline for separation */
  padding-bottom: 10px;
}

.owner-name {
  font-weight: bold;
  color: #800020; /* Highlighted color for owner */
}

.owner-tag {
  font-size: 12px;
  color: #555; /* Subtle color for the "Owner" tag */
  font-style: italic;
  margin-left: 5px;
}

/* Owner Indicator Button */
.owner-indicator-button {
  background-color: #ffc107; /* Yellow to stand out */
  color: #333; /* Dark text for contrast */
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  cursor: not-allowed; /* Indicate it's non-clickable */
  font-weight: bold;
}

.owner-indicator-button:hover {
  background-color: #e0a800; /* Slightly darker on hover for effect */
}

/* Update loader styles */

/* Page loader - covers entire page */
.page-loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Video section loader - centered in video grid */
.video-loader-wrapper {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30vh; /* Adjusted height to use viewport units */
  width: 100%;
}

/* Shared loader spinner style */
.acc-loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: acc-spin 1s linear infinite;
}

@keyframes acc-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Remove any existing loader styles that might conflict */
.account-loader-wrapper {
  display: none;
}

/* Add to your existing CSS */
.video-management-section {
  margin-top: 2rem;
  padding: 1rem;
}

.video-management-section h2 {
  margin-bottom: 1rem;
}


/* Add any additional styles you need */

/* Account Video Management - Custom Components */
.account-video-section {
  width: 100vw;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.account-video-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 1px solid #e0e0e0;
  background-color: white;
}

.account-video-header h2 {
  font-size: 24px;
  color: #002244;
  margin: 0;
}

/* Custom Video Grid for Account Page */
.account-video-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  padding: 2rem;
  width: 100%; /* Ensure it spans entire viewport width */
  min-height: calc(100vh - 400px); /* Maintain consistent height */
}

/* Video Card Styling */
.account-video-card {
  background: white;
  border-radius: 8px;
  border: 10px solid white;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  overflow: hidden;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05); /* Add a paler black outline */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  
}

.account-video-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.account-thumbnail-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background-color: #f0f0f0;
}

.account-thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.account-video-info {
  padding: 1rem;
}

.account-video-title {
  font-size: 1rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.75rem;
  line-height: 1.4;
}

.account-video-footer {
  display: flex;
  align-items: center;
  padding-top: 0.75rem;
  border-top: 1px solid #f0f0f0;
}

.account-surgeon-name {
  color: #666;
  font-weight: 500;
  font-size: 0.875rem;
}

.account-upload-date {
  color: #888;
  font-size: 0.813rem;
  margin-left: auto;
  margin-right: 1rem;
}

/* Action Buttons Container */
.account-video-actions {
  display: flex;
  gap: 0.5rem;
  margin-left: auto;
}

/* Edit Button */
.account-edit-button {
  background-color: white; /* Blue */
  color: #007bff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.2s ease;
  border: 1px solid #007bff;
}

.account-edit-button:hover {
  background-color: #007bff; /* darker shade of blue */
  color: white;
}

/* Delete Button */
.account-delete-button {
  padding: 6px 12px;
  background-color: transparent;
  color: #d9534f;
  border: 1px solid #d9534f;
  border-radius: 4px;
  font-size: 0.813rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.account-delete-button:hover {
  background-color: #d9534f;
  color: white;
}

/* Loading State */
.account-loader-wrapper {
  top:50vh;
  left:50vw;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Empty State */
.account-no-videos {
  width: 100%;
  padding: 3rem;
  text-align: center;
  background-color: white;
  border-radius: 8px;
  border: 2px dashed #e0e0e0;
  grid-column: 1 / -1;
}

/* Pagination Styles */
.account-pagination {
  grid-column: 1 / -1; /* Span all columns */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  padding: 1rem 0;
}

.account-pagination-button {
  background: none;
  border: 1px solid #002244;
  color: #002244;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 1rem;
}

.account-pagination-button:hover:not(.disabled) {
  background-color: #002244;
  color: white;
}

.account-pagination-button.disabled {
  border-color: #ccc;
  color: #ccc;
  cursor: not-allowed;
}

.account-pagination-info {
  font-size: 1rem;
  color: #666;
  margin: 0 1rem;
}

/* Search container styles */
.account-search-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: 60vw;
  width: 100%;
}

/* Search input styles */
.account-search-input {
  flex: 1;
  padding: 0.75rem 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 0.875rem;
  transition: border-color 0.2s ease;
  background-color: #f8f8f8;
}

.account-search-input:focus {
  outline: none;
  border-color: #002244;
  background-color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .account-video-header {
    flex-direction: column;
    gap: 1rem;
  }

  .account-search-container {
    max-width: 100%;
  }
}


/* Clear Button (an "×" icon) */
.account-clear-button {
  background: transparent;
  border: none;
  color: #002244;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0 0.5rem;
  line-height: 1;
}

.account-clear-button:hover {
  color: #001830;
}



/* Search Button Styling (unchanged) */
.account-search-button {
  padding: 0.75rem 1rem;
  background-color: #002244;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-search-button:hover {
  background-color: #001830;
}

/* Search Wrapper */
.account-search-container {
  display: flex;
  align-items: center;
  margin-top: 1rem; /* Adjust as needed */
}

/* Smaller integrated search field */
.search-wrapper {
  margin-right: auto; /* Push it to the left */
  display: flex;
  position: relative;
  width: 60vw; /* Adjusted width to prevent collapse */
  height: 4vh; /* Adjusted height */
}

.search-input-field {
  flex: 1;
  font-size: 1rem; /* Reduced font size */
  padding: 0.3rem 2rem 0.3rem 0.8rem; /* Adjusted padding for smaller size */
  border: 1px solid #ccc;
  border-radius: 25px;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-input-field:focus {
  border-color: var(--primary-dark);
}

/* The search icon button inside the input */
.search-icon-button {
  position: absolute;
  top: 50%;
  right: 2.5rem; /* Adjusted position for better spacing */
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem; /* Adjusted size */
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-icon-button:hover {
  color: var(--primary);
}

/* Clear button styling */
.account-clear-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #dc3545; /* Red color for clear button */
  font-size: 1rem; /* Adjusted size */
  margin-left: 0.5rem; /* Space between search icon and clear button */
  align-self: center; /* Center the button vertically */
}


.account-thumbnail-container {
  position: relative;
  /* Ensure it defines the positioning context for the overlay */
  overflow: hidden;
}

.info-bubble {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.92);
  color: #333;
  padding: 1rem;
  text-align: center;
  opacity: 0;             /* Hidden by default */
  pointer-events: none;   /* So it doesn't block clicks on the image */
  transition: opacity 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertical centering */
  align-items: center;     /* Horizontal centering */
}

.account-thumbnail-container:hover .info-bubble {
  opacity: 1;
}

/* Optional: style individual pieces of info */
.info-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.info-procedure,
.info-description,
.info-surgeon {
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

/* Section Styling */
.edit-section {
  display: flex;
  flex-direction: column;
  gap: 6px;
  background: #f9f9f9;
  padding: 12px;
  border-radius: 8px;
}

.edit-section h4 {
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
}

/* Input & Select Styling */
.edit-section input,
.edit-section select,
.edit-section textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
}

.edit-section textarea {
  resize: vertical;
  min-height: 80px;
}

/* Privacy Toggle */
.privacy-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Allowed Users & Groups Lists */
.allowed-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.allowed-item {
  background: #f0f0f0;
  padding: 8px;
  margin: 4px 0;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.allowed-item span {
  font-weight: bold;
  color: #333;
}

/* Remove Button (For Future) */
.remove-button {
  background: transparent;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 14px;
}

.remove-button:hover {
  text-decoration: underline;
}

.save-button {
  background: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}

.save-button:hover {
  background: #0056b3;
}

.cancel-button {
  background: #ccc;
  color: #333;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}

.cancel-button:hover {
  background: #bbb;
}

/* Add Button */
.add-button {
  background: #28a745;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}

.add-button:hover {
  background: #218838;
}

/* React Select Styling */
.react-select__control {
  border-radius: 6px !important;
}

.react-select__menu {
  border-radius: 6px !important;
}

.react-select__option {
  font-size: 14px;
}


/* Privacy Radios */
.privacy-radios {
  display: flex;
  gap: 1rem;
  margin-top: 6px;
}

.privacy-radios label {
  display: flex;
  align-items: center;
  gap: 4px;
}

/* Access Toggle Tabs */
.access-toggle {
  display: flex;
  gap: 8px;
  margin: 0.5rem 0;
}

.access-tab {
  padding: 6px 12px;
  border: 1px solid #ccc;
  background: #f7f7f7;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}

.access-tab.active {
  background: #007bff;
  border-color: #007bff;
  color: #fff;
}

/* Access Lists */
.access-container {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.access-section h5 {
  margin: 0 0 4px 0;
  font-size: 14px;
  font-weight: 600;
}
/* Make the allowed list a fixed height & scrollable */
.allowed-list {
  list-style: none;
  margin: 8px 0 0 0;
  padding: 0;
  max-height: 150px; /* adjustable */
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 4px;
  background: #fff;
}

/* Each allowed item row */
.allowed-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  border-bottom: 1px solid #eee;
}

/* The text portion on the left */
.allowed-text {
  color: #333;
  font-size: 14px;
}

/* Last item with no border */
.allowed-item:last-child {
  border-bottom: none;
}

/* Remove button: small X on the right */
.remove-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 0;
  margin-left: 8px;
}

.remove-button:hover {
  color: red;
}

/* If you want a custom hover style for .allowed-item */
.allowed-item:hover {
  background: #f9f9f9;
}

.access-separator {
  margin: 1rem 0;
  border: none;
  border-top: 1px solid #ddd;
}

/* Section Titles */
.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ddd;
  padding-bottom: 0.5rem;
}

.section-title-2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ddd;
  padding-bottom: 0.5rem;
  margin-top: 5rem !important;

}

/* Save Button */
.video-edit-save-button {
  flex: 1;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.6rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  max-width: 49%; /* Make sure both buttons are even */
}

.video-edit-save-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.video-edit-save-button:hover:enabled {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Cancel Edit Button */
.video-edit-cancel-button {
  flex: 1;
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 0.6rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  max-width: 49%; /* Ensure both buttons are even */
}

.video-edit-cancel-button:hover {
  background-color: #545b62; /* Darker grey on hover */
}

.close-modal-button {
  cursor: pointer;
}


/* Current Upload Section */
.current-upload-section {
  margin: 1.5rem 0;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
}

/* Section Title */
.section-title {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #222;
  letter-spacing: 0.3px;
}

/* Metadata Display */
.metadata-display {
  margin-bottom: 1rem;
  background: #f8f8f8;
  padding: 1rem;
  border-radius: 8px;
  border-left: 4px solid #007bff;
}

/* Distinct file name display */
.file-name-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.5rem 1rem;
  background: #f1f1f1;
  border-radius: 6px;
}


.file-name {
  font-size: 1rem;
  font-weight: 500;
  color: #007bff;
  word-break: break-word;
}

/* Title & Description */
.metadata-display h3 {
  margin: 0 0 0.25rem 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.metadata-display p {
  margin: 0;
  font-size: 0.95rem;
  color: #555;
}

/* Upload Progress */
.upload-progress-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.progress-bar-background {
  position: relative;
  width: 100%;
  height: 14px;
  background-color: #e0e0e0;
  border-radius: 7px;
  overflow: hidden;
}

.progress-bar-foreground {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: linear-gradient(90deg, #007bff 0%, #0056b3 100%);
  transition: width 0.3s ease-in-out;
  border-radius: 7px;
}

/* Upload Status */
.upload-status-text p {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: #444;
  text-align: center;
}

/* Hover effect for better UX */
.current-upload-section:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Responsive Tweaks */
@media (max-width: 768px) {
  .current-upload-section {
    padding: 1rem;
  }

  .progress-bar-background {
    height: 10px;
  }

  .upload-status-text p {
    font-size: 0.9rem;
  }
}
/* Upload Icon Button */
.acc-upload-icon-button {
  background: white; /* Initial white background */
  border: 2px solid var(--primary-dark); /* Blue border for visibility */
  border-radius: 50%; /* Circular button */
  cursor: pointer;
  padding: 0.5rem; /* Ensures balanced padding */
  width: 3rem; /* Fixed width */
  height: 3rem; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem; /* Add margin to the left for spacing */
  transition: background-color 0.3s ease, transform 0.3s ease, border-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for emphasis */
  aspect-ratio: 1 / 1; /* Ensures it's perfectly round */
}

/* Icon within the button */
.acc-upload-video-icon {
  width: 1.6rem; /* Adjusted size of the icon */
  height: 1.6rem; /* Define explicit height to prevent stretching */
  fill: #002244; /* Darker blue icon color for better visibility */
  transition: fill 0.3s ease;
}

/* Hover Effect */
.acc-upload-icon-button:hover {
  border-color: #32CD32; /* Green border on hover */
  transform: scale(1.1); /* Slight enlargement on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.acc-upload-icon-button:hover .acc-upload-video-icon {
  fill: var(--primary); /* Standard blue icon on hover */
}