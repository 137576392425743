.cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #323232;
    color: #fff;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
    font-family: Arial, sans-serif;
  }
  
  .cookie-consent__message {
    max-width: 80%;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .cookie-consent__message a {
    color: #4caf50;
    text-decoration: underline;
  }
  
  .cookie-consent__button {
    background: #4caf50;
    border: none;
    color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.2s ease;
  }
  
  .cookie-consent__button:hover {
    background: #43a047;
  }